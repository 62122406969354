import React from "react";
import detail_bg from "../Assets/bg_img/detail_bg.webp";
// import DetailsContent from "./DetailsContent";
import ProjectDetail from "./ProjectDetail";
function Details() {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${detail_bg})` }}
        className="h-[450px] bg-cover bg-center flex justify-center items-center w-full"
      >
        {/* <h2 className="text-yellow-100 hover:text-yellow-200 text-4xl md:text-6xl text-center font-merriweather font-bold p-3">Property for Sale in Mukteshwar</h2> */}
      </div>

      <ProjectDetail/>
     
    </>
  );
}

export default Details;
