import React from "react";

const ProjectDetail = () => {
  return (
    <div className="flex flex-col gap-2   bg-[#d2d2b4] bg-cover bg-opacity-60 p-3 justify-center text-[#74613C]">
      <h1 className="text-3xl md:text-5xl text-[#74613C] p-5 font-merriweather mx-auto text-justify">
        Cottages in Mukteshwar For Sale- Your Serene Escape Awaits
      </h1>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3">
        Discover the charm of Mukteshwar nestled amidst the Kumaon hills with
        our luxury cottages for sale. Perfectly blending contemporary comfort .
        These cottages offer a peaceful retreat amidst nature's beauty. Whether
        you seek a weekend getaway or a permanent abode in the lap of the
        Himalayas, our cottages in Mukteshwar provide you to experience life's
        simple joys.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3">
        Our cottages in Nainital offer breathtaking views of snow-capped peaks,
        valleys, and dense forests right from your doorstep. Wake up to the
        melodious chirping of birds and the fresh mountain air, as you sip your
        morning tea on the veranda or cozy up by the fireplace with a good book.
        Our customers say- these are one of the finest homes in Uttarakhand
        hills.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3 ">
        Located amidst the neighborhood, this property boasts breathtaking views
        of the surrounding hills and lush greenery. The studio apartment in
        Uttarakhand is thoughtfully designed to maximize space and
        functionality, featuring a comfortable sleeping area, a well-appointed
        kitchenette, and a modern bathroom.
      </p>

      <h2 className="text-2xl md:text-4xl text-[#74613C] p-5 font-merriweather mx-auto text-justify">
        Key Features of our property in Uttarakhand
      </h2>

      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3">
        <span className="font-semibold"> Scenic Location: </span> Enjoy the
        beauty of nature with hilly views of the Himalayas and verdant valleys
        right from your doorstep.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3">
        <span className="font-semibold"> Peaceful Environment:</span> Experience
        ambience and peacefulness in the heart of the mountains, away from the
        noise and pollution of urban areas.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3 ">
        <span className="font-semibold"> Well-Appointed Accommodation:</span>{" "}
        The property features well-designed living spaces equipped with modern
        amenities to ensure comfort and convenience.
      </p>

      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3">
        <span className="font-semibold"> Spacious Gardens:</span> Embrace
        outdoor living with expansive gardens perfect for relaxation,
        meditation, or hosting gatherings with friends and family.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3">
        <span className="font-semibold"> Explore Attractions:</span> Explore
        nearby attractions such as Mukteshwar Temple, Chauli Ki Jali, and Bhalu
        Gaad Waterfalls, offering opportunities for adventure and cultural
        experiences.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3 ">
        <span className="font-semibold">Investment Potential:</span> Whether as
        a rental property or investment opportunity, this home in Mukteshwar
        promises excellent returns in one of India's tourist destinations.
      </p>

      <div className="text-2xl md:text-4xl text-[#74613C] p-5 font-merriweather mx-auto text-justify">
        Why Mukteshwar?
      </div>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3 ">
        Mukteshwar, with its pleasant climate, lush forests, and captivating
        vistas, has emerged as a favored destination for nature lovers and
        adventure enthusiasts alike. From trekking and birdwatching to apple
        orchards and colonial-era architecture, Mukteshwar offers a unique blend
        of experiences that appeal to travelers seeking an authentic mountain
        getaway.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3 ">
        Mukteshwar offers a compelling choice for those seeking a serene retreat
        away from the hustle and bustle of popular tourist spots in Uttarakhand
        like Nainital, Bhimtal, and Mussoorie. Situated at an elevation of 8000
        feet above sea level, Mukteshwar boasts a peaceful atmosphere and
        breathtaking views that are unmatched. The lower property rates here
        present excellent potential for investment, making it an attractive
        option for those seeking to capitalize on the region's growth.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3 ">
        Unlike Nainital, which has experienced a decline in snowfall in recent
        years, Mukteshwar reliably receives snowfall between December and
        February every year. This consistent snowfall adds to Mukteshwar's charm
        as a winter destination, making it a preferred choice for those seeking
        snowy landscapes and a serene environment away from the tourist crowds.
        Moreover, its average temperature, which is consistently 5 - 10 degrees
        cooler than nearby hill stations like Nainital and Bhimtal, provides a
        refreshing escape from the summer heat.
      </p>
      <p className="md:mx-16 text-lg md:text-xl font-merriweather text-justify mx-3 ">
        Choose Mukteshwar for its peaceful ambiance, investment opportunities,
        and distinct climate—a perfect blend for those looking to invest in a
        tranquil retreat or a promising property venture. Experience
        Mukteshwar's unique appeal and discover why it stands as a preferred
        choice among Uttarakhand's hill stations.
      </p>

      <h3 className="text-2xl md:text-4xl text-[#74613C] p-5 font-merriweather mx-auto text-justify">
        Why To Invest In Mukteshwar:
      </h3>

      <ul className="flex flex-col gap-3 md:px-16 text-lg md:text-xl font-merriweather w-full">
      <li className="flex gap-3">
          <p> &#x2022; </p> Mukteshwar land cost is increasing exponentially. Post-COVID, we have seen 35% growth in land rates, which will go higher in upcoming years. Some so many families have moved permanently to the Indus Valley over the years. Investment can not always be calculated as to how much return you are getting. Sometimes, getting pure air and a peaceful environment can be counted as a mental investment for the soul.
        </li>
        <li className="flex gap-3">
          <p> &#x2022; </p> Investment Potential: With its growing popularity as
          a tourist destination and a rise in demand for vacation homes,
          investing in Mukteshwar real estate offers a promising appreciation
          value.
        </li>
        <li className="flex gap-3">
          <p>&#x2022;</p> Escape from the hustle and bustle: Break free from the
          hustle and bustle of city life and embrace a slower pace amidst
          nature's bounty. Mukteshwar offers a peaceful sanctuary away from the
          chaos.
        </li>
        <li className="flex gap-3">
          <p>&#x2022;</p> Year-round vacations Whether blanketed in snow during
          winters or adorned with blooming flowers in summers, Mukteshwar
          captivates visitors with its timeless beauty throughout the year.
          Owning a cottage here ensures year-round vacations and cherished
          memories.
        </li>
        <li className="flex gap-3">
          <p>&#x2022;</p> Large Open Personal Garden
        </li>
        <li className="flex gap-3">
          <p>&#x2022;</p> Wide Front View
        </li>
      </ul>

      <p className="text-lg md:text-xl font-merriweather text-center font-semibold pt-10 mx-3 ">
        Don't miss out on this opportunity to own your slice of paradise in
        Mukteshwar! <br/> Explore more about our company by clicking on the link
        provided...
      </p>
      <a
        className="bg-[#74613C] bg-opacity-50 border-2 text-2xl md:text-4xl w-fit mx-auto md:px-20 md:hover:bg-white hover:border-[#74613C] md:hover:text-[#74613C] hover:underline text-white border-white duration-700  rounded-3xl text-center px-2 font-bodoni py-2 my-5"
        href="https://theshubhamgroup.com/"
        target="_blank"
        aria-label="theshubhamgroup website link"
      >
        theshubhamgroup.com
      </a>
    </div>
  );
};

export default ProjectDetail;
