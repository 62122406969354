import React from "react";

const Banner2 = () => {
  return (
    <div className="relative">
      <video
        className="w-full h-auto xl:h-[850px] object-cover"
        autoPlay
        loop
        muted
        playsInline
        controls
        poster="/bg_video/bg2_video.jpg"
      >
        <source
          // src="https://res.cloudinary.com/dpkvvpwv6/video/upload/v1718865211/Indus%20VAlley%20Mukteshwar/Banners/IndusvalleyBanner2_eqcqzx.mp4"
          src="https://res.cloudinary.com/dqeka0ibm/video/upload/v1719483681/IndusvalleyBanner2_pk5p94.mp4"
          type="video/mp4"
        />
        {/* Add additional source elements for other video formats if needed */}
      </video>
    </div>
  );
};

export default Banner2;
