import React, { useState } from 'react';
import line from '../Assets/bg_img/underline.png';
import Map from "../Assets/photos/Route Map.png"


function RouteMap() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseClick = () => {
    setIsFullScreen(false);
  };

  const handleOverlayClick = (e) => {
    // Prevent closing when clicking on the image itself
    if (e.target.tagName !== 'IMG') {
      handleCloseClick();
    }
  };

  return (
    <div className="margin-lg-115t margin-sm-50t no-padd">
      <div
        data-vc-full-width="true"
        data-vc-full-width-init="true"
        data-vc-stretch-content="true"
        className="contact_map row-fluid no-padd margin-lg-115t margin-sm-50t no-padd relative box-border"
      >
        <div className="google-maps w-full mx-auto">
          <h3 className="w-fit text-[#74613C] mx-auto text-2xl md:text-4xl md:py-10 font-merriweather text-center p-3">
            ROUTE MAP <br />
            <img className="w-96" src={line} alt="underline" />
          </h3>
          <img
            className="w-full md:w-[50vw] h-80 cursor-pointer"
            // src="https://res.cloudinary.com/dqeka0ibm/image/upload/v1719829839/Route_Map_db0qmf.png"
            src={Map}
            alt="Route Map"
            onClick={handleImageClick}
          />
        </div>
      </div>

      {isFullScreen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50"
          onClick={handleOverlayClick}
        >
          <button  onClick={handleCloseClick}>
 <button
            className="absolute top-4 right-4 text-white text-3xl"
            onClick={handleCloseClick}
          >
            &times;
          </button>
          <img
            className="w-full h-full md:p-20 object-contain"
            // src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1718192990/Indus%20VAlley%20Mukteshwar/Route%20Map/Route_Map_anahz2.png"
            src={Map}
            alt="Route Map Full Screen"
          />
          </button>
         
        </div>
      )}
    </div>
  );
}

export default RouteMap;
