import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  IconButton,
  Input,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./myModal.css";
import Lottie from "lottie-react";
import animationData from "../../Assets/lottie/thankyou.json";

const phoneRegex = /^\d{7,10}$/;

const MyModal = ({ open, handleClose }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({ name: false, phoneNumber: false });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    const nameError = name === "";
    const phoneError = !phoneRegex.test(phoneNumber);
    setErrors({
      name: nameError,
      phoneNumber: phoneError,
    });

    if (!nameError && !phoneError) {
      setSubmitted(true);
      setName(() => "");
      setPhoneNumber(() => "");
    }
  };

  const handleNameChange = (e) => {
    setErrors({ ...errors, name: false });
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setErrors({ ...errors, phoneNumber: false });
    if (numericValue.length > 10) return;
    setPhoneNumber(numericValue);
  };

  const onClose = () => {
    setName("");
    setPhoneNumber("");
    setErrors({ name: false, phoneNumber: false });
    setSubmitted(false); // Reset the submission state
    handleClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      {/* <Box className="modal-container">
        <IconButton onClick={onClose} className="close-button">
          <CloseIcon />
        </IconButton>
        {!submitted ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" component="h2" className="modal-title">
                Connect to mother nature with Indus Valley
              </Typography>
            </Box>
            <Box className="modal-form">
              <>
                <InputLabel htmlFor="name" className="input-label" required>
                  Name
                </InputLabel>
                <Input
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                  error={errors.name}
                  fullWidth
                  required
                  disableUnderline
                  className={
                    errors.name ? "modal-input input-error" : "modal-input"
                  }
                />
                <FormHelperText className="input-error-text">
                  {errors.name ? "Name is required" : ""}
                </FormHelperText>
              </>
              <>
                <InputLabel
                  htmlFor="phone-number"
                  className="input-label"
                  required
                >
                  Phone Number
                </InputLabel>
                <Input
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={errors.phoneNumber}
                  fullWidth
                  required
                  type="tel"
                  disableUnderline
                  className={
                    errors.phoneNumber
                      ? "modal-input input-error"
                      : "modal-input"
                  }
                />
                <FormHelperText className="input-error-text">
                  {errors.phoneNumber ? "Invalid phone number" : ""}
                </FormHelperText>
              </>

              <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                className="modal-button"
              >
                Submit
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Lottie
              animationData={animationData}
              className="lottie-animation"
              loop={true}
              autoplay={true}
              speed={1}
            />
            <Typography variant="h6" component="h2" className="thank-you-text">
              Thank you for reaching out! Our Sales Manager will be in touch
              shortly.
            </Typography>
          </>
        )}
      </Box> */}
      <Box className="modal-container">
        <iframe
          id="IVM Landing Page"
          src="https://account.solidperformers.com/capture_form_data/MTI1OA=="
          border="0"
          style={{
            width: "100%",
            height: "100%",
            minHeight: "100vh",
          }}
        ></iframe>
      </Box>
    </Modal>
  );
};

export default MyModal;
