import React from "react";

const Banner = () => {
  return (
    <div className="relative">
      <video
        className="w-full h-auto xl:h-[850px] object-cover"
        autoPlay
        loop
        muted
        playsInline
        controls
        poster="/bg_video/bg5_video.jpg"
      >
        <source
         src="/bg_video/IndusvalleyBanner.mp4" type="video/mp4"
          // src="https://res.cloudinary.com/dpkvvpwv6/video/upload/v1717671030/Indus%20VAlley%20Mukteshwar/Banners/bg5_video_pgq27q.mp4"
        
        />
        {/* Add additional source elements for other video formats if needed */}
      </video>
    </div>
  );
};

export default Banner;
