import React from "react";
import apartment1 from "../Assets/r2m/apartment1.webp";
import plot_01 from "../Assets/r2m/plot_01.webp";
import apartment4 from "../Assets/r2m/apartment4.webp";
import plot_27 from "../Assets/r2m/plot_27.webp";
import apartment5 from "../Assets/r2m/apartment5.webp";
import apartment6 from "../Assets/r2m/apartment6.webp";
import apartment09 from "../Assets/r2m/p_09.jpg";
// import coming_soon from "../Assets/r2m/coming-soon-display-background-with-focus-light_1017-33741.avif";
import plot_5_6 from "../Assets/r2m/Plot_5_6.webp";
import log_simplex from "../Assets/r2m/simplex front.jpg";
import log_duplex from "../Assets/r2m/3.jpg";
import line from "../Assets/bg_img/underline.png";

const properties = [
  {
    link: "/Studio_1bhk_Apart_layout",
    img: apartment1,
    title: "STUDIO APARTMENT",
    plot: "Plot No- 69 : F1/F2",
    price: "35 Lakhs",
  },

  {
    link: "/Plot_09",
    img: apartment09,
    title: "1 BHK APARTMENT",
    plot: "Plot No- 09",
    price: "55 Lakhs",
  },

  {
    link: "/Plot_01",
    img: plot_01,
    title: "2 BHK Cottage",
    plot: "Plot No- 01 (Site B)",
    price: "1 Cr",
  },
  {
    link: "/Plot_01",
    img: plot_01,
    title: "2 BHK Cottage",
    plot: "Plot No- 02 (Site B)",
    price: "1 Cr",
  },
  {
    link: "/Plot_27",
    img: plot_27,
    title: "3 BHK Cottage",
    plot: "Plot No- 27",
    price: "1.40 Cr",
  },
  {
    link: "/Cottage_4bhk",
    img: apartment6,
    title: "4 BHK Cottage",
    plot: "Plot No- 43",
    price: "1.85 Cr",
  },
  // {
  //   link: "#",
  //   img: coming_soon,
  //   title: "Coming Soon...",
  //   plot: "Plot No- XX",
  //   price: "xx",
  // },
];

const underConstruction = [
  {
    link: "/Plot_5_6",
    img: plot_5_6,
    title: "STUDIO & 1 BHK APARTMENTS",
    plot: "Plot No- 5/6",
    price: "(35 Lakhs to 50 Lakhs)",
  },
  {
    link: "/Plot_26",
    img: apartment4,
    title: "2 BHK APARTMENTS",
    plot: "Plot No- 26",
    price: "(70 Lakhs to 75 Lakhs)",
  },
  {
    link: "/Plot_36",
    img: apartment5,
    title: "2 BHK APARTMENTS",
    plot: "Plot No- 36",
    price: "(60 Lakhs to 65 Lakhs)",
  },
];

const semiWoodenCottages = [
  {
    link: "/Wooden_2bhk",
    img: log_simplex,
    title: "2 BHK SIMPLEX",
    status: "UNDER CONSTRUCTION",
    price: "(1.30 Cr to 1.40 Cr)",
  },
  {
    link: "/Wooden_3bhk",
    img: log_duplex,
    title: "3 BHK DUPLEX",
    status: "UNDER CONSTRUCTION",
    price: "(1.50 Cr to 1.70 Cr)",
  },
];

const PropertyCard = ({ link, img, title, plot, price, status }) => (
  <div className="w-fit rounded-xl border-2 bg-[#FAF9F6] shadow-lg m-auto md:hover:scale-90 duration-500">
    <a href={link}>
      <img className="rounded-t-xl w-screen md:w-96 h-64" src={img} alt={title} />
    </a>
    <div className="flex flex-col justify-center items-center p-3">
      <h2 className="text-xl text-[#74613C] font-merriweather">{title}</h2>
      {plot && <p className="text-lg font-merriweather">{plot}</p>}
      {status && <p className="text-xl text-[#74613C] font-merriweather">{status}</p>}
      <div className="flex flex-col md:flex-row justify-center items-center gap-2">
        {price && <p className="text-xl text-[#74613C] font-semibold font-merriweather">Price Rate - {price}</p>}
      </div>
    </div>
  </div>
);

const Ready_To_Move2 = () => {
  return (
    <>
      <section className="bg-cover bg-center flex flex-col">
        <h2 className="w-fit text-[#74613C] mx-auto text-2xl md:text-4xl md:pt-10 font-merriweather text-center p-3">
          READY TO MOVE-IN <br />
          <img className="w-96" src={line} alt="underline" />
          <h1 className="w-fit text-[#74613C] mx-auto text-sm md:text-xl font-merriweather text-center px-3">(LUXURY HOUSE IN MOUNTAINS)</h1>
        </h2>

        <div className="flex flex-col md:p-5">
          <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 overflow-hidden gap-3 md:gap-14 p-3">
            {properties.map((property, index) => (
              <PropertyCard key={index} {...property} />
            ))}
          </section>

          <h2 className="w-fit text-[#74613C] mx-auto text-2xl md:text-4xl md:py-10 font-merriweather text-center p-3">
            UNDER CONSTRUCTION
            <br />
            <img className="w-96 md:w-[550px]" src={line} alt="underline" />
          </h2>

          <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 overflow-hidden gap-3 md:gap-14 p-3">
            {underConstruction.map((property, index) => (
              <PropertyCard key={index} {...property} />
            ))}
          </section>

          <h2 className="w-fit text-[#74613C] mx-auto text-2xl md:text-4xl md:py-10 font-merriweather text-center p-3">
            PREMIUM SEMI WOODEN COTTAGES <br />
            <img className="w-96 md:w-[700px]" src={line} alt="underline" />
          </h2>

          <section className="mx-auto flex flex-col md:flex-row justify-center overflow-hidden gap-3 md:gap-14 p-3">
            {semiWoodenCottages.map((property, index) => (
              <PropertyCard key={index} {...property} />
            ))}
          </section>
        </div>
      </section>
    </>
  );
};

export default Ready_To_Move2;
