import React from "react";
// import icon_facebook from '../assets/icons/icon_facebook.png';
// import icon_insta from '../assets/icons/icon_insta.png';
// import icon_linkdin from '../assets/icons/icon_linkdin.png';
// import icon_twitter from '../assets/icons/icon_twitter.png';
import brand_logo from "../Assets/icons/the-shubham-group1final.png";
import footer3 from "../Assets/bg_img/detail_bg.webp";

const Footer = () => {
  return (
    <footer
      style={{ backgroundImage: `url(${footer3})` }}
      className=" bg-cover bg-bottom "
    >
      <div className="flex flex-col md:flex-row border-t-2 ">
        <div className="flex flex-col md:flex-row w-full justify-around mx-auto px-5 md:px-0 xl:px-5 gap-5 md:gap-0 xl:gap-5 bg-[#BDC0A4] bg-opacity-90 ">
          {/* <section className="flex flex-col px-5 md:w-1/3 w-full gap-3 md:gap-5">
            <p className="text-black text-2xl md:text-3xl font-merriweather font-semibold">
              Registered Office
            </p>
            <p className="text-black text-base font-merriweather font-thin hover:underline">
              The Shubham Group of Companies, <br />
              A-24, Okhla Industrial Area, Phase-2, <br />
              Delhi. Pincode - 110020
            </p>
          </section> */}
          <section
            className="flex flex-col w-full items-center justify-center 
      mx-auto text-center"
          >
            <a
              href="https://www.theshubhamgroup.com"
              target="_blank"
              rel="noreferrer"
            >
              {/* <img
              className=" rounded-lg mx-auto px-10 md:px-0 md:py-10 md:pr-16 items-center hover:shadow-lg hover:shadow-slate-300 animate__zoomIn"
              src={brand_logo}
              alt="Brand Logo.."
            /> */}
              <img
                className=" rounded-lg p-2 h-48 md:h-60 pt-5 items-center animate__zoomIn"
                src={brand_logo}
                alt="Brand Logo.."
              />
            </a>
          </section>
          <section className="flex flex-col items-center text-center md:text-start px-5  w-full gap-3 md:mt-5">
            <p className="text-black text-2xl md:text-3xl font-merriweather font-semibold">
              Site Address
            </p>
            <p className="text-black text-base md:text-lg font-merriweather font-thin ">
              Managher, Near Dhanachuli Bend,
              <br /> Village - Sunderkhal,
              <br /> Tehsil - Dhari,
              <br />
              Nainital, Uttrakhand.
              <br /> Pincode - 263132
            </p>
          </section>

          <section className="flex flex-col items-center text-center md:text-start px-5 w-full md:mt-5 gap-3">
            <p className="text-black text-2xl md:text-3xl font-merriweather font-semibold">
              Corporate Office
            </p>
            <p className="text-black text-base md:text-lg font-merriweather font-thin ">
              315, Block-B, Sector-105, Noida,
              <br />
              Uttar Pradesh. Pincode - 201304
            </p>
          </section>

          <section className="flex flex-col items-center text-center md:text-start px-5 md:pl-10 w-full gap-3 md:mt-5">
            <p className="text-black text-2xl md:text-3xl font-merriweather font-semibold">
              Contact Us
            </p>
            <div className="flex flex-col">
              <a
                className="text-black text-base md:text-lg hover:underline hover:text-blue-400 "
                href="mailto:info@theshubhamgroup.com"
              >
                info@theshubhamgroup.com
              </a>

              <a href="tel:8510850101">
                <p className="text-black text-base md:text-lg hover:text-yellow-600">
                  8510 850 101
                </p>
              </a>

              <p className="text-black text-base  pt-2">
                Prashant Sharma
                <br /> (Site Manager)
              </p>
              <a href="tel:9711258989">
                <p className="text-black text-base md:text-lg hover:text-yellow-600">
                  9711 258 989
                </p>
              </a>
            </div>
          </section>
        </div>
      </div>

      <section className="py-5 md:pt-0 bg-[#BDC0A4] bg-opacity-90 copy-right">
        <p className="text-black text-center text-sm font-merriweather ">
          Copyright © 2009 The Shubham Group All rights reserved.
        </p>
      </section>
    </footer>
  );
};

export default Footer;
