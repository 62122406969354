import React from "react";
import { Typography } from "@material-tailwind/react";

function Testimonials() {
  return (
    <div className="w-full  bg-cover md:bg-opacity-80 py-5 md:py-10">
      <h3 className="text-2xl md:text-4xl text-center py-5 font-merriweather">
        Voice Of Indus Valley
      </h3>
      <div className="md:p-5 md:mx-14 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 m-auto">
          <section className="text-center p-4 md:py-8 font-glacial hover:scale-105 hover:duration-500">
            <Typography>
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/lxsUgVAG5DU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Typography>
          </section>

          <section className="text-center p-4 md:py-8 font-glacial hover:scale-105 hover:duration-500">
            <Typography>
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/JJ4YaA4K2dg"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Typography>
          </section>

          <section className="text-center p-4 md:py-8 font-glacial hover:scale-105 hover:duration-500">
            <Typography>
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/3VCF5F7AUx4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Typography>
          </section>

          <section className="text-center p-4 md:py-8 font-glacial hover:scale-105 hover:duration-500">
            <Typography>
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/ZPsYsz52Hms?start=2"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Typography>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
